.m_btn {
  color: #b1a9a9 !important;
}
.m_btn:hover {
  color: #ff6295 !important;
}
.product_image_detail {
  overflow: hidden;
  width: 100%;
  height: 500px;
  display: flex !important;
  align-items: center;
  justify-content: center;
}
.product_image_detail img {
  font-family: Poppins;
  width: 500px !important;
}
.product_details_gallery_images .desc_galery_images_box {
  border-radius: 6px;
}
.product_details_gallery_images .desc_galery_images_box img {
  width: 90%;
  height: 100px;
  width: 100px;
  padding: 4px;
  border-radius: 6px;
  border: 1px solid #cecdcd;
}
.product_details_gallery_images img:hover {
  cursor: pointer;
}
.desc_galery_images_box_selected {
  border: 1px solid rgb(247, 49, 100) !important;
}
.main_desc_cont {
  display: flex;
  justify-content: center;
  padding: 10px 75px !important;
}
.Image_cont {
  width: 62%;
}
.desc_cont {
  width: 40%;
}

.chip {
  font-family: Poppins-Bold;
  color: black;
  font-size: 22px;
  min-width: 100px;
  float: right;
  font-weight: bold;
  border-radius: 7px;
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.prod_item_change {
  width: 115px;
  margin-right: 10px;
  /* border: 1px solid #bab9b9; */
  display: flex !important;
  justify-content: center !important;
  border-radius: 4px !important;
  /* height: 42px !important; */
  height: 40px;
  border: 1px solid #d7d2d2 !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center;
}

.flexx {
  display: flex;
  /* justify-content: start; */
  flex-direction: row;
  margin-bottom: 30px;
}

.boxx {
  border-radius: 1.5rem;
  background-color: white;
}

.buttonCustom {
  font-family: JellyDonuts;
  background-color: #ff6295;
  color: white;
  /* font-size: 1.4rem; */
  font-size: 0.8rem;
  border-radius: 4px;
  height: 42px;
}
.prod_des_name {
  font-family: Poppins-Bold;
  font-size: 1.4rem;
  font-size: 1.9rem;
  margin-bottom: 15px;
  font-weight: 700;
  text-transform: capitalize;
  color: #ff6295;
}
.mg {
  font-family: Poppins;
  font-weight: 600 !important;
  font-size: 15px;
  /* margin: 4px; */
  color: black;
  width: 68%;
  padding-left: 0.5rem;
  font-weight: 500;
}
.FW {
  position: relative;
  width: 36%;
  font-family: Poppins-Bold;
  font-size: 18px;
  word-break: break-all;
  padding-right: 6px;
  color: #ff82ab;
  font-weight: 700;
}
/* .FW:after {
  content: ":";
  position: absolute;
  right: 4px;
  top: 0;
  margin-top: -3px;
} */

.allergen_product_box {
  margin-top: 20px;
  display: flex;
}
.allergen_product_box .allergen_p_left {
  padding-top: 0.75rem;
  padding-right: 0.5rem;
}
.allergen_product_box .allergen-title {
  font-weight: 700;
  margin-bottom: 0;
  font-family: Poppins-Bold;
  color: #ff82ab;
}
.allergen_product_box .allergen_p_right {
  display: flex;
  flex-wrap: wrap;
}
.allergen_product_box .allergen_image {
  height: 50px;
  margin-left: 5px;
  margin-right: 5px;
  margin-bottom: 8px;
}
.Custom_add_to_cart {
  width: 320px;
  margin-right: 5px;
}

.bottom_product_detail_box .bottom_price_info span {
  color: rgb(37, 37, 37);
}
.bottom_product_detail_box_wrapper {
  background-color: white;
  position: fixed;
  bottom: 0;
  opacity: 0;
  left: 0;
  right: 0;
  padding: 0.25rem 0.5rem;
  box-shadow: 0 0 20px 0 rgb(0 0 0 / 15%);
  z-index: 99;
  border-top: 1px solid lightgray;
  transition: all 0.25s ease-in-out;
}
.bottom_product_detail_box {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.bottom_product_detail {
  /* width: 50%; */
  display: flex;
  align-items: center;
}
.bottom_add_to_cart {
  margin-left: 10px;
  /* width: 50%; */
  display: flex;
  justify-content: end;
  align-items: center;
}
.bottom_add_to_cart .buttonCustom {
  outline: none !important;
  box-shadow: none !important;
  border: none !important;
  width: 200px;
}
.bottom_image_box {
  width: 3.5rem;
}
.bottom_product_info {
  margin-left: 10px;
  /* width: 55%; */
  width: auto;
}
.bottom_product_info .bottom_product_name {
  font-family: Poppins-Bold;
  margin-bottom: 0;
  font-size: 1.1rem;
  line-height: 1.2;

  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.bottom_price_info {
  /* margin-left: auto; */
  margin-bottom: 0;
  min-width: 80px;
  margin-left: 10px;
  text-align: start;
}
.bottom_price_info span {
  font-family: Poppins-Black;
  font-size: 1.2rem;
}
.out_of_stock {
  background-color: pink;
}

@media (min-width: 1440px) {
  .desc_galery_images_box img {
    height: 150px !important;
    width: 150px !important;
  }
  .bottom_image_box {
    width: 4.5rem;
  }
}

.bottom_cart_wrapper .bottom_cart_btn {
  font-family: JellyDonuts;
  background-color: #ff6295;
  color: white;
  font-size: 0.8rem;
  border-radius: 4px;
  width: 200px;
  height: 42px;
}
.bottom_price_change_btns {
  margin-right: 10px;
}
.bottom_price_change_btns .bottom_item_change {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgb(176, 175, 175);
  border-radius: 4px;
  height: 42px;
  padding-left: 8px;
  padding-right: 8px;
}
.bottom_price_change_btns .bottom_item_change svg {
  cursor: pointer;
}
.bottom_price_change_btns .bottom_item_change .prod_item_num {
  min-width: 30px;
  text-align: center;
}
.prod_item_num {
  font-family: Poppins !important;
  font-family: bold;
}

.product_item_added_alert_wrapper {
  display: flex;
  justify-content: center;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;
}
.product_item_added_alert {
  width: 100%;
}
.product_item_added_alert .MuiPaper-root {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  border-width: 2px !important;
  /* background-color: rgb(237, 247, 237, 0.6); */
  background-color: #FFFFFF60
}
.product_item_added_alert_body {
  color: #000;
  font-weight: 600;
}
.alert_text {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
}
.MuiAlert-message {
  width: 50%;
}

@media (max-width: 500px) {
  .MuiAlert-message {
    width: 55%;
  }
  .quantity {
    text-transform: lowercase !important;
  }
}
@media (max-width: 350px) {
  #alert_qty {
    width: 35%;
  }
}

.product_item_added_alert_body p {
  margin-bottom: 0;
  text-transform: capitalize;
}
.quantity {
  text-transform: lowercase !important;
}
.product_item_added_alert_action_btns {
  display: flex;
  align-items: flex-start;
}
.product_item_added_alert_action_btns .btn_view {
  margin-top: -5px;
}
.btn_close {
  margin-left: 15px !important;
}
.product_item_added_alert_action_btns {
  transform: translate(8px, -8px);
}

@media (max-width: 1199.98px) {
  .product_image_detail img {
    width: 500px !important;
  }
}

@media (max-width: 1072px) {
  /* .bottom_product_detail_box {
    flex-wrap: wrap;
  } */
  /* .bottom_product_detail {
    width: 100%;
  } */
  .bottom_add_to_cart {
    /* width: 100%; */
    justify-content: center;
    margin-top: 0.25rem;
  }
}
@media (min-width: 1600px) {
  .Custom_inc_dec {
    width: 30%;
  }
  .main_desc_cont .prod_item_change {
    width: 94%;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .flexx {
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;
  }
  .mg {
    font-family: Poppins;
    font-weight: 600 !important;
    font-size: 15px;
    color: black;
    width: 68%;
    font-weight: 500;
    padding-left: 0;
  }
  .FW {
    width: 100%;
  }

  .bottom_image_box {
    width: 3.5rem;
  }
}

@media (min-width: 768px) and (max-width: 1023px) {
  /* .prod_item_change {
    width: 30%;
  } */
  .main_desc_cont {
    padding: 0px !important;
    flex-direction: column;
  }
  .Image_cont {
    width: 100%;
    margin-top: 20px;
  }
  .desc_cont {
    width: 100%;
  }
  .desc_galery_images_box img {
    width: 80% !important;
    height: 135px !important;
    width: 135px !important;
  }
  .chip {
    text-align: center;
    font-size: 1rem;
  }
}

@media (min-width: 650px) and (max-width: 767px) {
  .bottom_product_info {
    width: auto;
  }
  .bottom_cart_wraper .bottom_cart_btn {
    font-size: 0.8rem;
    width: 200px;
    height: 42px;
  }
  .bottom_price_change_btns {
    margin-right: 10px;
  }
  .bottom_price_change_btns .bottom_item_change {
    height: 42px;
    padding-left: 8px;
    padding-right: 8px;
  }
  .prod_item_change {
    width: 30%;
  }
  .flexx {
    flex-wrap: wrap;
  }
  .FW {
    width: 40%;
    font-size: 15px;
  }
  .mg {
    width: 50%;
    font-size: 12px;
    font-weight: bold;
  }
  .product_image_detail {
    height: 400px;
  }
  .product_image_detail img {
    width: 400px !important;
  }
  .main_desc_cont .prod_item_change {
    width: 100%;
    margin-right: 0px !important;
    max-width: 100%;
    justify-content: space-between !important;
    margin-bottom: 0px;
    height: 43px !important;
  }
  .parent_add_to_cart {
    padding: 0;
  }
  .Custom_add_to_cart {
    margin: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
  }
  .Custom_inc_dec {
    margin-right: 10px !important;
  }
  .product_details_gallery_images {
    display: none;
  }
  .main_desc_cont {
    flex-direction: column;
    padding: 0px !important;
  }
  .Image_cont {
    width: 100%;
    margin-top: 20px;
  }
  .desc_cont {
    width: 100%;
  }
  .bottom_add_to_cart .parent_add_to_cart {
    flex-direction: row;
  }
  .bottom_add_to_cart .Custom_add_to_cart {
    width: auto;
  }
  .bottom_product_info .bottom_product_name {
    font-size: 0.8rem;
  }
  .bottom_price_info span {
    font-size: 1rem;
  }
}

@media (max-width: 650px) {
  .Image_cont {
    margin-top: 20px;
  }
}

@media (max-width: 575px) {
  .bottom_cart_wrapper .bottom_cart_btn {
    width: 60px;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bottom_price_info {
    min-width: 60px;
  }
}

@media (min-width: 426px) and (max-width: 650px) {
  .prod_item_change {
    width: 30%;
  }
  .bottom_add_to_cart .buttonCustom {
    outline: none !important;
    box-shadow: none !important;
    border: none !important;
    margin-right: 20px !important;
  }
  .main_desc_cont .prod_item_change {
    width: 100%;
    margin-right: 0px !important;
    max-width: 100%;
    justify-content: space-between !important;
    margin-bottom: 0px;
    height: 43px !important;
  }
  .parent_add_to_cart {
    padding: 0;
  }
  .Custom_add_to_cart {
    margin: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
  }
  .Custom_inc_dec {
    margin-right: 10px !important;
  }
  .product_details_gallery_images {
    display: none;
  }
  .main_desc_cont {
    flex-direction: column;
    padding: 0px !important;
  }

  .Image_cont {
    width: 100%;
  }
  .desc_cont {
    width: 100%;
  }
  .bottom_add_to_cart .parent_add_to_cart {
    flex-direction: row;
  }
  .bottom_add_to_cart .Custom_add_to_cart {
    width: auto;
  }
  .bottom_product_info .bottom_product_name {
    font-size: 0.8rem;
  }
  .bottom_price_info span {
    font-size: 1rem;
  }
}

@media (max-width: 500px) {
  .flexx {
    flex-wrap: wrap;
  }
  .FW {
    width: 40%;
    font-size: 21px;
  }
  .mg {
    width: 50%;
    font-size: 15px;
    font-weight: bold;
  }
  .product_image_detail {
    height: 400px;
  }
  .main_desc_cont .prod_item_change {
    width: 100%;
    margin-right: 0px !important;
    max-width: 100%;
    justify-content: space-between !important;
    margin-bottom: 0px;
    height: 43px !important;
  }
  .parent_add_to_cart {
    padding: 0;
  }
  .Custom_add_to_cart {
    margin: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
  }
  .Custom_inc_dec {
    margin-right: 10px !important;
  }
  .product_details_gallery_images {
    display: none;
  }
  .main_desc_cont {
    flex-direction: column;
    padding: 0px !important;
  }
  .Image_cont {
    width: 100%;
  }
  .desc_cont {
    width: 100%;
  }
  .bottom_add_to_cart {
    margin-left: 0px;
  }
  .bottom_product_info {
    margin-left: 10px;
    width: 65%;
  }
  .bottom_add_to_cart .parent_add_to_cart {
    flex-direction: row;
  }
  .bottom_add_to_cart .Custom_add_to_cart {
    width: auto;
  }
  .bottom_product_info .bottom_product_name {
    font-size: 0.8rem;
  }
  .bottom_price_info span {
    font-size: 1rem;
  }
  /* ---- */
  .bottom_price_info {
    min-width: 60px;
  }
  .bottom_price_change_btns {
    margin-right: 7px;
    margin-left: 5px;
  }
  .bottom_cart_wrapper .bottom_cart_btn {
    width: 95px !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .chip {
    text-align: center;
    min-width: 80px;
  }
}

@media (max-width: 390px) {
  .main_desc_cont .prod_item_change {
    width: 100%;
    margin-right: 0px !important;
    max-width: 100%;
    justify-content: space-between !important;
    margin-bottom: 10px;
  }
  .bottom_product_detail_box_wrapper {
    padding: 5px 0px;
  }
  .bottom_add_to_cart .prod_item_change {
    height: 42px !important;
    margin-bottom: 0 !important;
  }
  .bottom_add_to_cart .buttonCustom {
    line-height: 1;
    font-size: 18px;
    height: 42px;
  }
  .FW {
    width: 40%;
    font-size: 18px;
  }
  .mg {
    width: 50%;
    font-size: 13px;
    font-weight: bold;
  }
  .product_image_detail {
    height: 400px;
  }
  .parent_add_to_cart {
    flex-direction: column;
    padding: 0;
  }
  .Custom_add_to_cart {
    margin: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
  }
  .Custom_inc_dec {
    margin: 0px !important;
  }
  .product_details_gallery_images {
    display: none;
  }
  .main_desc_cont {
    flex-direction: column;
    padding: 0px !important;
  }
  .Image_cont {
    width: 100%;
  }
  .desc_cont {
    width: 100%;
  }

  .chip {
    min-width: 70px;
  }

  .bottom_add_to_cart {
    margin-left: 0px;
    width: 57%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom_price_change_btns {
    padding-left: 0;
  }

  .bottom_item_change {
    width: 85px;
  }
  .bottom_cart_wrapper {
    width: 90px;
    margin-right: 5px;
  }
  .bottom_cart_wrapper .bottom_cart_btn {
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 320px) {
  .main_desc_cont .prod_item_change {
    width: 100%;
    margin-right: 0px !important;
    max-width: 100%;
    justify-content: space-between !important;
  }

  .parent_add_to_cart {
    flex-direction: column;
    padding: 0;
    align-items: center;
  }
  .Custom_add_to_cart {
    margin: 0px !important;
    margin-bottom: 5px !important;
    width: 100%;
  }
  .Custom_inc_dec {
    margin: 0px !important;
    margin-bottom: 10px !important;
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .product_details_gallery_images {
    display: none;
  }
  .main_desc_cont {
    flex-direction: column;
    padding: 0px !important;
  }
  .Image_cont {
    width: 100%;
  }
  .desc_cont {
    width: 100%;
  }
  .FW {
    width: 40%;
    font-size: 14px;
  }
  .mg {
    width: 50%;
    font-size: 12px;
    font-weight: bold;
  }
  .product_image_detail {
    height: 400px;
  }

  .bottom_product_detail {
    width: 46%;
  }
  .bottom_product_info {
    margin-left: 10px;
    width: 50%;
  }
  .bottom_price_info {
    min-width: 60px;
    margin-left: 0px;
  }
  .chip {
    min-width: 60px;
  }

  .bottom_add_to_cart {
    margin-left: 0px;
    width: 54%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .bottom_price_change_btns {
    margin-right: 5px;
  }
  .bottom_price_change_btns {
    padding-left: 0;
  }

  .bottom_item_change {
    width: 85px;
  }
  .bottom_cart_wrapper {
    width: 75px;
  }
  .bottom_cart_wrapper .bottom_cart_btn {
    width: 95% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

@media (max-width: 300px) {
  .chip {
    padding-right: 0px !important;
    min-width: 50px;
  }
  .bottom_cart_wrapper .bottom_cart_btn {
    width: 80% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .bottom_cart_wrapper {
    width: 75px;
  }
  .bottom_product_info {
    margin-left: 6px;
  }
  .bottom_price_change_btns {
    margin-right: 5px;
    margin-left: 7px;
  }
}
