.prodCart_box {
  position: relative;
  cursor: pointer;
  z-index: 999999;
}
.prodCart_Icon {
  color: #ea2666;
  font-size: 36px !important;
}
.prodCart_Icon path {
  fill: #fff !important;
}
.prodCart_items_added {
  position: absolute;
  top: 0;
  right: 0;
  font-weight: bolder;
  transform: translateY(-50%);
  background-color: white;
  color: #ea2666;
  font-size: 12px;
  padding: 1px 5px;
  border-radius: 50%;
}

.nav_user_icon {
  color: #fff;
  font-size: 36px !important;
  cursor: pointer;
}

.navbar .nav-link {
  padding: 0;
  margin-right: 10px;
  border-radius: 25px;
}
.navbar-expand-lg .navbar-nav .nav-link {
  padding-left: 0 !important;
  padding-right: 0 !important;
  margin-left: 10px;
  margin-right: 10px;
}
.navbar .nav-link button {
  border-radius: 25px;
}

.navbar-collapse {
  /* z-index: 999999; */
  z-index: 999;
}

.navbar-nav .dropdown-menu {
  position: absolute !important;
  transform: translateX(-50%);
}

.prod_cart_box_wrapper .prod_cart_box {
  position: relative;
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin-right: 15px;
  cursor: pointer;
  /* --- */
  /* z-index: 999; */
  z-index: 99999;
}
.prod_cart_box_wrapper .prod_cart_box  {
  z-index: 99999;
}
.prod_cart_box_wrapper .prod_cart_box .prod_cart_box_icon_box {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0px;
}
.prod_cart_box_wrapper
  .prod_cart_box
  .prod_cart_box_icon_box
  .prod_cart_box_icon {
  font-size: 38px !important;
  color: #fff !important;
}
.prod_cart_box_wrapper .prod_cart_box .prod_cart_item_count {
  min-width: 25px;
  min-height: 25px;
  position: absolute;
  top: -9px;
  right: -9px;
  background-color: white;
  border-radius: 50%;
  font-size: 14px;
  line-height: 1;
  display: block;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgb(255, 98, 149);
  z-index: 999999;
  font-weight: bolder;
}

.desk_nav_dropdown .dropdown-item {
  font-family: Poppins-Bold !important;
  /* font-size: 14px; */
  color: #f73164;
  border-left: 1px solid #FFF;
  border-right: 1px solid #FFF;
}
.desk_nav_dropdown .dropdown-item a {
  color: #f73164 !important;
}
.desk_nav_dropdown .dropdown-item:hover {
  background-color: #f73164;
  color: #FFF;
}
.desk_nav_dropdown .dropdown-item:hover a {
  color: #FFF !important;
}
.desk_nav_dropdown .dropdown-item.disabled {
  color: pink;
}

@media screen and (min-width: 1460px) {
  #flow_right_image {
    margin-top: -9.5%;
    margin-left: 32%;
    position: absolute;
    height: 35vw;
  }
}

@media screen and (max-width: 1450px) {
  #flow_right_image {
    margin-top: -9rem;
    height: 35rem;
    margin-left: 30vw;
    position: absolute;
  }
}

@media screen and (max-width: 1300px) {
  #flow_right_image {
    margin-top: -11.5vw;
    height: 35rem;
    margin-left: 28.5vw;
    position: absolute;
  }
}

@media screen and (max-width: 1025px) {
  #flow_right_image {
    margin-left: 27.5vw;
    margin-top: -9.5vw;
    height: 25rem;
    position: absolute;
  }
}

@media screen and (max-width: 950px) {
  #flow_right_image {
    margin-left: 47.5vw;
    margin-top: -6.5rem;
    height: 25rem;
    width: 70%;
    position: absolute;
  }
}

@media screen and (max-width: 770px) {
  #flow_right_image {
    margin-left: 48%;
    margin-top: -2.2rem;
    height: 11rem;
    width: 70%;
    position: absolute;
  }
}

@media screen and (max-width: 600px) {
  #flow_right_image {
    margin-left: 44%;
    margin-top: -3.4rem;
    height: 15rem;
    position: absolute;
  }
}

@media screen and (max-width: 426px) {
  #container {
    margin-top: 0px !important;
  }
  #flow_right_image {
    margin-left: 10.5rem;
    margin-top: -2.3rem;
    height: 11rem;
    width: 70%;
  }
  #menu {
    margin-top: 10px !important;
  }
  #logo_image {
    height: 35px;
  }
}

@media screen and (max-width: 380px) {
  #flow_right_image {
    margin-left: 10.5rem;
    margin-top: -2.9rem;
    height: 13rem;
  }
  #menu {
    margin-top: 5px !important;
  }
}
@media screen and (max-width: 325px) {
  #flow_right_image {
    margin-left: 10.5rem;
    margin-top: -3rem;
    height: 13rem;
  }
  #menu {
    margin-top: 2px !important;
  }
  #logo_image {
    height: 35px;
  }
}

.navbar-nav {
  align-items: center;
}
.navbar-light .navbar-toggler {
  color: #ea2666;
  z-index: 99;
}

@media only screen and (min-width: 546px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 500px;
  }
}
@media only screen and (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 925px;
  }
}
@media only screen and (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1000px;
  }
}
@media only screen and (min-width: 2000px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1500px;
  }
}

.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.1rem;
}
.navbar-light .navbar-toggler {
  border-color: rgba(248, 93, 148, 0.5);
}

@media (max-width: 992px) {
  .navbar-nav .dropdown-menu {
    position: absolute !important;
    transform: translateX(-40%) !important;
  }
}

@media (max-width: 1159px) and (min-width: 900px) {
  /* #products {
    font-size: 22px;
  } */
  /* #store_header {
    font-size: 22px;
  } */
}

/* @media (max-width: 991.98px) {
  
} */

@media (max-width: 769px) {
  .prod_cart_box_wrapper {
    position: fixed;
    bottom: 76px;
    right: 20px;
    z-index: 999999;
  }
  .prod_cart_box_wrapper .prod_cart_box {
    border: 4px solid rgb(231, 42, 102);
    background-color: #ffffff;
    width: 52px;
    height: 52px;
    padding-top: 0px;
    padding-left: 0px;
    margin-right: 0px;
  }
  .prod_cart_box_wrapper .prod_cart_box .prod_cart_box_icon_box {
    padding: 3px;
    /* background-color: rgb(255, 98, 149); */
    background-color: rgb(231, 42, 102);
    z-index: 99999;
  }
  .prod_cart_box_wrapper
    .prod_cart_box
    .prod_cart_box_icon_box
    .prod_cart_box_icon {
    font-size: 28px !important;
    color: white !important;
  }
  .prod_cart_box_wrapper .prod_cart_box .prod_cart_item_count {
    top: -12px;
    right: -12px;
    background-color: rgb(231, 42, 102);
    color: white !important;
  }
}
