#slider_header > .slick-slider .slick-dots {
  position: relative;
  top: -75px;
}

@media (min-width: 768px) and (max-width: 900px) {
  #slider_header > .slick-slider .slick-dots {
    position: relative;
    top: 50px;
  }
}

#header_middle_content {
  margin-top: 14%;
}

#slider_header .slick-slider .slick-dots li.slick-active button {
  background: white;
}
#slider_header .slick-slider .slick-next {
  margin-right: 5pc;
  color: white;
}

#slider_header .slick-slider .slick-prev {
  margin-left: 5pc;
  color: white;
  z-index: 10;
}

#first_content {
  margin-top: 7pc;
}

#first_heading {
  font-size: 35px;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

#first_heading p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

#second_content {
  height: 280px;
  margin: 0;
}

#second_heading {
  font-size: 60px;
  color: white;
  font-weight: 700;
  font-family: "Poppins-Bold" !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

#imp_text {
  font-size: 24;
  color: white;
  font-weight: 600;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

#imp_button p {
  margin: auto;
}

#slick_fluid_container {
  overflow: hidden;
}

#second_grid {
  display: flex;
  justify-content: center;
  height: 37pc;
}

@media (min-width: 1250px) {
  #slider_header {
    height: 25pc !important;
  }
}

@media (max-width: 1200px) {
  #slider_header {
    height: 32pc;
  }
  #slider_header .slick-slider .slick-next {
    margin-right: 3pc;
    color: white;
  }

  #slider_header .slick-slider .slick-prev {
    margin-left: 3pc;
    color: white;
    z-index: 10;
  }
  #first_heading {
    font-size: 27px;
  }
  #second_heading {
    font-size: 45px;
  }
}

@media (max-width: 900px) {
  #slider_header .slick-slider .slick-next {
    margin-right: 3pc;
    color: white;
  }

  #slider_header .slick-slider .slick-prev {
    margin-left: 3pc;
    color: white;
    z-index: 10;
  }

  #first_heading {
    font-size: 35;
    color: "white";
    font-weight: 600;
  }

  #second_heading {
    font-size: 60;
    color: "white";
    font-weight: 700;
  }

  #imp_text {
    font-size: 24;
    color: white;
    font-weight: 600;
  }
}

@media (max-width: 850px) {
  #second_content {
    height: 15pc;
    margin: 0;
  }
  #slider_header {
    height: 88vh;
  }
  #root_grid {
    display: flex;
    flex-direction: column-reverse;
  }
  #first_heading {
    font-size: 22px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #first_heading p {
    margin: 0;
  }
  #second_heading {
    margin: 0;
    font-size: 5vh;
    text-align: center;
    color: white;
    font-weight: 700;
  }
  #second_heading p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  #imp_button {
    padding: 10px 30px 10px 30px;
  }
  #second_grid {
    max-height: 50vh;
  }

  #second_grid image {
    object-fit: contain;
  }

  #first_content {
    margin-top: 0px;
  }
  #imp_button {
    display: block;
    margin: auto;
    margin-top: 10px;
  }
  #imp_button p {
    margin: 0;
  }
  #imp_text {
    text-align: center;
  }
}
@media (max-width: 600px) {
  #slider_header {
    height: 100vh;
  }
  #root_grid {
    display: flex;
    flex-direction: column-reverse;
  }
  #first_heading {
    font-size: 22px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #first_heading p {
    margin: 0;
  }
  #second_heading {
    margin: 0;
    font-size: 5vh;
    text-align: center;
    color: white;
    font-weight: 700;
  }
  #second_heading p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  #imp_button {
    padding: 10px 30px 10px 30px;
  }
  #second_grid {
    max-height: 50vh;
  }

  #second_grid image {
    object-fit: contain;
  }

  #first_content {
    margin-top: 0px;
  }
  #imp_button {
    display: block;
    margin: auto;
    margin-top: 10px;
  }
  #imp_button p {
    margin: 0;
  }
  #imp_text {
    text-align: center;
  }
  .shop_now_btn {
    font-size: 1.2rem;
    line-height: 1.2;
    padding: 8px 45px 8px 45px;
  }
}

@media (max-width: 500px) {
  #second_content {
    height: 22pc;
    margin: 0;
  }
  /* #slider_header {
    height: 106vmax;
  } */
  #root_grid {
    display: flex;
    flex-direction: column-reverse;
  }

  #slider_header {
    height: 49pc;
  }
  #first_heading {
    font-size: 22px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #first_heading p {
    margin: 0;
  }
  #second_heading {
    margin: 0;
    font-size: 5vh;
    text-align: center;
    color: white;
    font-weight: 700;
  }
  #second_heading p {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3; /* number of lines to show */
    line-clamp: 3;
    -webkit-box-orient: vertical;
  }
  #imp_button {
    padding: 10px 30px 10px 30px;
  }
  #second_grid {
    height: 52vh;
  }

  #first_content {
    margin-top: 0px;
  }
  #imp_button {
    display: block;
    margin: auto;
    margin-top: 1.8pc;
  }
  #imp_button p {
    margin: 0;
  }
  #imp_text {
    text-align: center;
  }
  .shop_now_btn {
    font-size: 0.9rem;
    line-height: 1.2;
    padding: 8px 45px 8px 45px;
  }
  #imp_button {
    font-size: 12px;
  }
}

@media (min-height: 550px) {
  #slider_header {
    height: 43pc;
  }
}

@media (min-height: 700px) and (max-height: 800px) {
  #slider_header {
    height: 45pc;
  }
  #second_content {
    height: 20pc;
  }
}

@media (min-height: 840px) {
  #slider_header {
    height: 56pc;
  }
}

@media (min-height: 950px) and (max-height: 1024px) {
  #second_content {
    height: 22pc;
    margin: 0;
  }
}

@media (min-height: 1025px) {
  #slider_header {
    height: 75pc;
  }
  #second_content {
    height: 20pc;
    margin: 0;
  }
}

@media (max-width: 380px) {
  #first_heading {
    font-size: 18px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #second_content {
    height: 20pc;
    margin: 0;
  }
  #second_heading {
    font-size: 26px;
    text-align: center;
    color: white;
    font-weight: 700;
  }
}

@media (max-width: 325px) {
  #first_heading {
    font-size: 16px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #second_heading {
    font-size: 22px;
    text-align: center;
    color: white;
    font-weight: 700;
  }
}

@media (max-width: 285px) {
  #first_heading {
    font-size: 15px;
    color: white;
    text-align: center;
    font-weight: 600;
  }
  #second_heading {
    font-size: 24px;
    text-align: center;
    color: white;
    font-weight: 700;
  }
}
