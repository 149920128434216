#trending_title {
  font-family: JellyDonuts;
  text-align: center;
  /* font-size: 40px; */
  font-size: 17px;
  margin: 0;
  margin-left: 5px;
  margin-right: 5px;
  color: #f36292;
}
#product_title {
  text-align: center;
  font-family: Poppins;
  margin: 0;
  font-size: 60px;
  line-height: 30px;
  color: #f36292;
}

#product_tag {
  border-radius: 50%;
  height: 75px;
  /* min-width: 40px; */
  width: 90px;
  height: 90px;
  /* width: max-content; */
  display: inline-block;
  font-size: 20px;
  position: absolute;
  padding: 0;
  margin: 0;
  margin-top: 0.2%;
  z-index: 20;
  margin-left: 20px;
  font-family: Poppins;
}
#product_name {
  font-family: Poppins;
  /* font-family: Poppins; */
  font-weight: bold;
  line-height: 1;
  padding-bottom: 2px;
  margin-bottom: 0;
  /* font-size: 18px; */
  font-size: 17px;
  margin-top: 12px;
  margin-left: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}

#product_price {
  font-family: Poppins-Bold;
  margin: 0;
  color: #f36292;
  font-size: 20px;

  /* line-height: 30px; */
  line-height: 20px;
}

#product_card {
  width: 95% !important;
  display: grid !important;
  margin: 0px 8px;
}
.slick-slider {
  margin-left: 2px;
}

@media screen and (max-width: 800px) {
  #product_name {
    font-family: Poppins-Bold;
    margin-bottom: 0;
    font-size: 16px;
    margin-top: 12px;
    margin-left: 6px;
  }
}

@media screen and (max-width: 325px) {
  #product_category {
    font-size: 26px;
  }
}
