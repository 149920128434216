.user_auth__wrapped {
  margin-top: 40px;
  margin-bottom: 40px;
}

.user_auth__wrapped h4 {
  font-family: Poppins-Bold;
}
.user_auth__wrapped label {
  font-family: Poppins;
}
.user_auth__wrapped input {
  font-family: Poppins;
}

.user_submit_btn {
  width: 100%;
  font-family: JellyDonuts !important;
  background-color: #ff6295;
  color: #ffffff !important;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  font-size: 16px !important;
  font-weight: 600;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}

/* .user_submit_btn:hover {
  background-color: #f83774 !important;
} */

.user_auth__wrapped .user_submit_btn:disabled {
  color: rgba(0, 0, 0, 0.36) !important;
}

.user_switch_account {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
.user_switch_account p {
  margin-bottom: 0;
  font-family: Poppins;
  font-size: 15px;
}
.user_switch_account button,
.user_switch_account button:focus {
  background-color: #ff6295;
  color: #ffffff !important;
  font-family: JellyDonuts;
  border: none;
  outline: none !important;
  box-shadow: none !important;
  font-size: 11px;
  margin-left: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
}
/* .user_switch_account button:hover {
  background-color: #f83774 !important;
} */

@media (max-width: 768px) {
  #return_button {
    font-size: 11px !important;
  }
}

@media (max-width: 500px) {
  #return_button {
    margin-top: 10px;
    font-size: 15px !important;
  }
}
